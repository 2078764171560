<template>
  <div class="u-lk-raport-dashboard-wrapper">
    <div class="u-lk-raport-dashboard-wrapper__inner">
      <div class="u-lk-raport-dashboard-wrapper__title u-lk-form__title">
        Дашборд о реализации Десятилетия
      </div>

      <div class="u-lk-raport-dashboard-wrapper__filter">
        <div class="u-lk-raport-dashboard-wrapper__filter-item">
          <div class="u-lk-raport-dashboard-wrapper__label">
            Начало периода
          </div>
          <CustomCalendar
            class="u-lk-raport-dashboard-wrapper__calendar"
            v-model="dateFromModel"
            :minDate="minDate"
            :maxDate="dateToModel"
            @input="onChangeDateFrom"
          />
        </div>
        <div class="u-lk-raport-dashboard-wrapper__filter-item">
          <div class="u-lk-raport-dashboard-wrapper__label">
            Конец периода
          </div>
          <CustomCalendar
            class="u-lk-raport-dashboard-wrapper__calendar"
            v-model="dateToModel"
            :minDate="dateFromModel"
            :maxDate="maxDate"
            @input="onChangeDateTo"
          />
        </div>
        <div class="u-lk-raport-dashboard-wrapper__filter-item u-select u-select--small">
          <div class="u-lk-raport-dashboard-wrapper__label">
            Инициатива
          </div>
          <div class="u-lk-raport-dashboard-wrapper__select-wrapper" :class="{ 'u-select--no-interactive': initiativesOptions.length <= 1 }">
            <v-select
              v-model="initiativeModel"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="initiativesOptions"
              placeholder="Все инициативы"
              multiple
              @input="onChangeInitiative"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
          </div>
        </div>
        <div class="u-lk-raport-dashboard-wrapper__filter-item u-select u-select--small">
          <div class="u-lk-raport-dashboard-wrapper__label">
            Регион
          </div>
          <div class="u-lk-raport-dashboard-wrapper__select-wrapper" :class="{ 'u-select--no-interactive': regionOptions.length <= 1 }">
            <v-select
              :value="regionModel"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="regionOptions"
              placeholder="Все регионы"
              multiple
              @input="onChangeRegion"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import vSelect from 'vue-select';
import { debounce } from 'lodash-es';

export default {
  components: { vSelect },

  props: {
    initiativesOptions: {
      type: Array,
      default: () => []
    },

    regionOptions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      minDate: null,
      maxDate: null,

      OpenIndicator: {
        render: createElement => createElement('span')
      },

      debounceLoadAll: debounce(this.LOAD_ALL, 100)
    }
  },

  computed: {
    ...mapGetters('lkRaportDashboard', ['FILTER', 'IS_LOADING']),

    otherCountry() {
      return this.regionOptions.find(item => item.code === 'otherCountry')
    },

    allRegion() {
      return this.regionOptions.find(item => item.code === 'all')
    },

    otherRegion() {
      return this.regionOptions.find(item => item.code === 'otherRegion')
    },

    uniqueOptionIds() {
      return [this.otherCountry, this.allRegion, this.otherRegion].filter(item => Object.values(item).some(Boolean)).map(item => item.id)
    },

    dateFromModel: {
      get() {
        return this.FILTER?.dateFrom || ''
      },
      set(value) {
        this.SET_FILTER({ dateFrom: value })
      }
    },

    dateToModel: {
      get() {
        return this.FILTER?.dateTo || ''
      },
      set(value) {
        this.SET_FILTER({ dateTo: value })
      }
    },

    regionModel: {
      get() {
        return this.FILTER?.region
      },
      set(value) {
        this.SET_FILTER({ region: value })
      }
    },

    initiativeModel: {
      get() {
        return this.FILTER?.initiativeId
      },
      set(value) {
        this.SET_FILTER({ initiativeId: value })
      }
    },
  },

  methods: {
    ...mapMutations('lkRaportDashboard', ['SET_STATE', 'SET_FILTER']),
    ...mapActions('lkRaportDashboard', ['LOAD_ALL']),

    init() {
      const minDate = new Date(2022, 0, 1)
      const maxDate = new Date()

      this.minDate = minDate.toLocaleDateString('ru-RU')
      this.maxDate = maxDate.toLocaleDateString('ru-RU')
      this.SET_FILTER({ dateTo: this.maxDate, dateFrom: this.minDate })
    },

    onChangeInitiative() {
      this.$nextTick().then(() => {
        this.loadAll()
      })
    },

    onChangeRegion(selectedRegions) {
      const selectedRegion = selectedRegions[selectedRegions.length - 1] || null

      if (
        (this.otherCountry && selectedRegion === this.otherCountry.id)
        || (this.allRegion && selectedRegion === this.allRegion.id)
        || (this.otherRegion && selectedRegion === this.otherRegion.id)
      ) {
        this.SET_FILTER({ region: [selectedRegion] })
      } else if (selectedRegions?.length > 1 && selectedRegions.some(item => this.uniqueOptionIds.includes(item))) {
        this.SET_FILTER({ region: selectedRegions.filter(item => !this.uniqueOptionIds.includes(item) ) })
      } else {
        this.SET_FILTER({ region: selectedRegions })
      }

      this.$nextTick().then(() => {
        this.loadAll()
      })
    },

    onChangeDateFrom() {
      this.$nextTick().then(() => {
        this.loadAll()
      })
    },

    onChangeDateTo() {
      this.$nextTick().then(() => {
        this.loadAll()
      })
    },

    loadAll() {
      this.SET_STATE({ name: 'isLoading', value: true })

      this.debounceLoadAll()
    }
  },

  created() {
    this.init()
  },

  watch: {
    IS_LOADING(isLoading) {
      this.$root.$emit('setLoading', isLoading)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-lk-raport-dashboard-wrapper';

#{$b} {

  // .u-lk-raport-dashboard-wrapper__title
  &__title {
    margin: 0 0 32px 0 !important;
  }

  // .u-lk-raport-dashboard-wrapper__filter
  &__filter {
    max-width: 824px;
    @include gridcols(2, 24px);

    @include tablet {
      max-width: 100%;
    }

    @include mobile {
      @include gridcols(1, 24px);
    }
  }

  // .u-lk-raport-dashboard-wrapper__label
  &__label {
    font-family: $font-family-inter;
    font-size: 12px;
    line-height: 1.3;
    text-transform: initial;
    margin-bottom: 8px;
    margin-left: 8px;
    font-weight: normal;
  }

  // .u-lk-raport-dashboard-wrapper__calendar
  &__calendar {
    @include tablet {
      width: 100% !important;
    }
  }
}
</style>
