import Vue from 'vue'
import Vuex from 'vuex'
import region from '@/store/modules/region'
import header from '@/store/modules/header'
import initiativesEvents from '@/store/modules/initiatives-events'
import lkReportTable from '@/store/modules/lk/report-table'
import modal from '@/store/modules/modal'
import lkEvents from '@/store/modules/lk/events'
import excursions from '@/store/modules/excursions'
import lkRaportDashboard from '@/store/modules/lk/raport-dashboard'
import profile from '@/store/modules/profile'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    region,
    header,
    initiativesEvents,
    lkReportTable,
    modal,
    lkEvents,
    excursions,
    lkRaportDashboard,
    profile,
  }
})
