<template>
  <div class="u-lk-raport-count">
    <div class="lk-count__container">
      <ULkCountGraph :events="graphList" withCompensatorWidth />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    initialItems: {
      type: Array,
      default: () => []
    },
  },

  computed: {
    ...mapGetters('lkRaportDashboard', ['PARTICIPANTS_LIST']),

    graphList() {
      let offlineValue = 0
      let onlineValue = 0

      this.PARTICIPANTS_LIST?.forEach(item => {
        offlineValue += item.statistics?.offline
        onlineValue += item.statistics?.online
      })

      const sum = offlineValue + onlineValue

      return [
        {
          color: '#003DFF',
          percent: offlineValue * 100 / sum,
          title: 'Очные',
          value: offlineValue
        },
        {
          color: '#97AFFC',
          percent: onlineValue * 100 / sum,
          title: 'Дистанционные',
          value: onlineValue,
        },
      ]
    }
  },

  methods: {
    ...mapMutations('lkRaportDashboard', ['SET_STATE']),

    init() {
      if (this.initialItems?.length) {
        this.SET_STATE({ name: 'participantsList', value: this.initialItems })
      }
    }
  },

   created() {
    this.init()
  },
}
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.u-lk-raport-count';

#{$b} {

  // .u-lk-raport-count__filter
  &__filter {
    margin-bottom: 32px;
  }

  // .u-lk-raport-count__subtitle
  &__subtitle {
    margin: 32px 0;
  }

  // .u-lk-raport-count__initiative
  &__initiative {
    margin: -15px 0 30px;
  }
}
</style>
