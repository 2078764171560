import axios from "axios"

export default {
  namespaced: true,
  state: {
    isAuth: false
  },

  getters: {
    IS_AUTH(state) {
      return state.isAuth
    }
  },

  mutations: {
    SET_AUTH_STATE(state, isAuth) {
      state.isAuth = isAuth
    }
  },

  actions: {
    async GET_PROFILE({ commit }) {
      try {
        const response = await axios('/local/ajax/getProfile.php')

        if (response?.data?.success && response.data.data) {
          commit('SET_AUTH_STATE', response.data.data.authorized)
        }
      } catch(e) {
        throw new Error(`store profile:GET_PROFILE | ${e}`)
      }
    }
  }
}
