<template>
  <div class="u-lk-raport-progress">
    <div class="u-lk-raport-progress__body">
      <div class="u-lk-raport-progress__row">
        <div
          class="u-lk-raport-progress__col"
          v-for="(item, i) in EVENTS_LIST"
          :key="`u-lk-raport-progress__col-${item.title}-${i}`"
        >
          <UProgressBar
            class="u-lk-raport-progress__progress-bar"
            :title="item.title"
            :values="{
              current: item.values.current,
              max: item.values.max
            }"
          />
        </div>
      </div>
      <slot name="note"></slot>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    initialItems: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      events: [],
      currentInitiativeName: '',
    }
  },

  computed: {
    ...mapGetters('lkRaportDashboard', ['EVENTS_LIST'])
  },

  methods: {
    ...mapMutations('lkRaportDashboard', ['SET_STATE']),

    init() {
      if (this.initialItems?.length) {
        this.SET_STATE({ name: 'eventsList', value: this.initialItems })
      }
    }
  },

  created() {
    this.init()
  },
}
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.u-lk-raport-progress';

#{$b} {

  // .u-lk-raport-progress__filter
  &__filter {
    display: inline-grid;
    grid-template-columns: repeat(2, auto);
    gap: 16px;
    margin-bottom: 24px;

    @include tablet {
      width: 100%;
      justify-content: space-between;
      gap: 0;
    }

    @include mobile {
      display: block;
    }
  }

  // .u-lk-raport-progress__calendar
  &__calendar {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .u-lk-raport-progress__row
  &__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    @include low-desktop {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      display: block;
    }
  }

  // .u-lk-raport-progress__col
  &__col {
    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .u-lk-raport-progress__initiative
  &__initiative {
    margin: -15px 0 15px;
  }
}

</style>
