<template>
    <div class="excursions-photos">
        <initiatives-news
            key-label="excursions-photos__swiper-item-"
            @button-click="handleThumbnailClick"
            :show-more="false"
            :initial-news-items="initialItems"
            initial-news-title="Фотоальбом"
            btn-text="Все фотографии"
            all-news-link="/photos"
            v-slot="{ cardData, callParentClickHandler, id }"
        >
            <div
                style="width: 100%; height: 300px"
                class="u-gallery-slider__item excursions-photos__item"
                :style="{ 'backgroundImage' : `url('${cardData.image}')` }"
                @click="callParentClickHandler(id)"
            ></div>
        </initiatives-news>
    </div>
</template>

<script>

export default {
    props: {
        initialItems: { type: Array, default: () => [] }
    },
    methods: {
        handleThumbnailClick(...args) {
            this.$root.$emit('openPopupGallery', args[0], this.galleryInLightBox)
        },
    },
    computed: {
        galleryInLightBox() {
            return this.initialItems.map((item) => ({
                cover: item.image,
                src: item.image,
                title: item.text,
            }))
        }
    }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

.excursions-photos {
  position: relative;
    &__item {
        transition: $transtion-default;
        &:hover {
            @include mobile-min {
                transform: scale(1.01)
            }
        }
    }
}
</style>
