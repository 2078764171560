import axios from 'axios'

/**
 *
 * @param { string } dateStr // string as DD.MM.YYYY
 *
 * @returns { string } // string as DD-MM-YYYY
 */
function formatDate(dateStr) {
  return dateStr && typeof dateStr === 'string'
    ? dateStr.split('.').join('-')
    : ''
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    errorsAfterSubmit: [],

    eventsList: [],
    participantsList: [],
    raportList: [],

    filter: {
      initiativeId: '',
      region: '',
      dateFrom: '',
      dateTo: ''
    }
  },

  getters: {
    IS_LOADING(state) {
      return state.isLoading
    },

    ERRORS_AFTER_SUBMIT(state) {
      return state.errorsAfterSubmit
    },

    FILTER(state) {
      return state.filter
    },

    EVENTS_LIST(state) {
      return state.eventsList
    },

    PARTICIPANTS_LIST(state) {
      return state.participantsList
    },

    RAPORT_LIST(state) {
      return state.raportList
    }
  },

  mutations: {
    SET_STATE(state, { name, value }) {
      if (!Object.hasOwn(state, name)) {
        return console.warn(`store:program | SET_STATE - свойство ${name} не объявлено в state экземпляре`)
      }

      state[name] = value
    },

    SET_FILTER(state, filter, withReplace = false) {
      state.filter = withReplace ? filter : { ...state.filter, ...filter }
    }
  },

  actions: {
    async LOAD_ALL({ commit, dispatch }) {
      try {
        commit('SET_STATE', { name: 'isLoading', value: true })
        return await Promise.all([
          dispatch('LOAD_EVENTS'),
          dispatch('LOAD_PARTICIPANTS'),
          dispatch('LOAD_RAPORT')
        ])
      } catch(e) {
        throw new Error(`store: lkPaportDashbord|LOAD_ALL - ${e}`)
      } finally {
        commit('SET_STATE', { name: 'isLoading', value: false })
      }
    },

    async LOAD_EVENTS({ commit, getters }) {
      try {
        const options = {
          params: getters.FILTER,
          url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:activity.report&action=getStatistics'
        }

        const response = await axios(options)

        if (response?.data?.status === 'success' && Array.isArray(response?.data?.data)) {
          commit('SET_STATE', { name: 'eventsList', value: response?.data?.data })
        }

      } catch(e) {
        throw new Error(`store: lkPaportDashbord|LOAD_EVENTS - ${e}`)
      }
    },

    async LOAD_PARTICIPANTS({ commit, getters }) {
      try {
        const options = {
          params: getters.FILTER,
          url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:participants.dashboard&action=getStatistics'
        }

        const response = await axios(options)

        if (response?.data?.status === 'success' && Array.isArray(response?.data?.data)) {
          commit('SET_STATE', { name: 'participantsList', value: response?.data?.data })
        }

      } catch(e) {
        throw new Error(`store: lkPaportDashbord|LOAD_PARTICIPANTS - ${e}`)
      }
    },

    async LOAD_RAPORT({ commit, getters }) {
      try {
        const options = {
          params: {
            ...getters.FILTER,
            dateFrom: formatDate(getters.FILTER.dateFrom),
            dateTo: formatDate(getters.FILTER.dateTo),
          },
          url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:report.dashboard&action=getStatistics'
        }

        const response = await axios(options)

        if (response?.data?.status === 'success' && Array.isArray(response?.data?.data)) {
          commit('SET_STATE', { name: 'raportList', value: response?.data?.data })
        }

      } catch(e) {
        throw new Error(`store: lkPaportDashbord|LOAD_RAPORT - ${e}`)
      }
    }
  }
}
