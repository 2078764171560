<template>
  <div class="u-lk-raport-initiatives">
    <div class="u-lk-raport-initiatives__body">
      <div v-if="RAPORT_LIST?.length" class="u-lk-raport-initiatives__list">
        <div
          class="u-lk-raport-initiatives__card"
          v-for="card in RAPORT_LIST"
          :key="`u-lk-raport-initiatives__card-${card.id}`"
        >
          <div class="u-lk-raport-initiatives__card-title title-lg ff-title" v-html="card.name" />

          <div v-if="card.fields?.length" class="u-lk-raport-initiatives__card-list">
            <div
              class="u-lk-raport-initiatives__card-item"
              v-for="item in card.fields"
              :key="`u-lk-raport-initiatives__card-item-${card.id}-${item.id}`"
            >
              <div class="u-lk-raport-initiatives__card-caption" v-html="item.caption" />
              <div class="u-lk-raport-initiatives__card-value">
                <div v-if="item.value || item.value === 0" class="u-lk-raport-initiatives__card-number title-lg" v-html="formattedValue(item.value)" />
                <div v-if="item.valueName" class="u-lk-raport-initiatives__card-units" v-html="item.valueName" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <not-found v-else text="По данным параметрам ничего не найдено" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('lkRaportDashboard', ['RAPORT_LIST'])
  },

  methods: {
    formattedValue(value) {
      return Number(value).toLocaleString('ru-RU');
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-lk-raport-initiatives';

#{$b} {

  // .u-lk-raport-initiatives__card
  &__card {
    padding: 32px;
    border-radius: 16px;
    background-color: $white-true;

    @include tablet {
      padding: 24px;
    }

    @include mobile {
      padding: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    // .u-lk-raport-initiatives__card-title
    &-title {
      margin-bottom: 32px;

      @include mobile {
        margin-bottom: 16px;
      }
    }

    // .-lk-raport-initiatives__card-item
    &-item {
      &:not(:last-child) {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba($color-black, 0.2);

        @include mobile {
          padding-bottom: 16px;
          margin-bottom: 16px;
        }
      }
    }

    // .u-lk-raport-initiatives__card-value
    &-value {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    // .u-lk-raport-initiatives__card-number
    &-number {
      color: $color-base-origin;
    }

    // .u-lk-raport-initiatives__card-units
    &-units {
      color: rgba($color-black, 0.5);
    }
  }
}
</style>
