<template>
  <form class="auth-form" @submit.prevent="submitForm">
    <div class="auth-form__field">
      <UTextInput
        class="u-text-input--smaller"
        name="email"
        label="Логин"
        placeholder="E-mail"
        input-type="email"
        v-model="form.email"
        :error="$v.form.email.$error"
      />
      <ErrorFormWrapper :isVisible="$v.form.email.$error && (!$v.form.email.required || !$v.form.email.email)">
        <div v-show="$v.form.email.$error && !$v.form.email.required">Обязательное поле</div>
        <div v-show="$v.form.email.$error && !$v.form.email.email">Неправильный e-mail</div>
      </ErrorFormWrapper>
    </div>
    <div class="auth-form__field">
      <a href="#" class="auth-form__recovery-link" @click.prevent="changeForm('recovery')">
        Забыл пароль
      </a>
      <UTextInput
        class="u-text-input--smaller"
        name="password"
        label="Пароль"
        placeholder="Пароль"
        v-model="form.password"
        input-type="password"
        :error="$v.form.password.$error"
        isPassword
      />
      <ErrorFormWrapper :isVisible="$v.form.password.$error && (!$v.form.password.required || !$v.form.password.minLength)">
        <div v-show="$v.form.password.$error && !$v.form.password.required">Обязательное поле</div>
        <div v-show="$v.form.password.$error && !$v.form.password.minLength">Должен содержать не менее 8 символов</div>
      </ErrorFormWrapper>
    </div>
    <ErrorFormWrapper
      class="auth-form__errors-after-submit"
      :isVisible="Boolean(errorsAfterSubmit.length)"
    >
      <div v-for="(error,i) in errorsAfterSubmit" :key="`error-after-submit-${i}`">
        {{ error.message }}
      </div>
    </ErrorFormWrapper>
    <div class="auth-form__bottom">
      <CustomButton
        class="auth-form__bottom-btn"
        type="button"
        theme="primary"
        size="md"
        @click.prevent="submitForm"
      >
        Войти
      </CustomButton>
      <div class="auth-form__bottom-text">
        Ещё нет аккаунта?
        <a href="#" @click.prevent="changeForm('registration')">
          Зарегистрироваться
        </a>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "AuthForm",
  mixins: [validationMixin],
  props: {
    action: {
      type: String,
      default: ""
    },
    acceptPolicy: {
      type: Boolean,
      default: false
    },
    bus: {
      type: Object,
    },
    lkUrl: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      formSent: false,
      form: {
        email: "",
        password: "",
      },
      errorsAfterSubmit: [],
      tabLk: '',
    };
  },
  validations: {
    form: {
      email: { required, email },
      password: { required, minLength: minLength(8) },
      acceptPolicy: {}
    }
  },
  created() {
    this.bus.$on('submitLogin', () => {
      this.submitForm();
    });
    this.$root.$on('goToTabAfterAuth', this.setTabLk)
  },
  computed: {
    resultLkRoute() {
      return this.lkUrl || '/lk/'
    }
  },
  methods: {
    async submitForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const formData = new FormData();
        formData.append('email', this.form.email);
        formData.append('password', this.form.password);
        formData.append('acceptPolicy', this.acceptPolicy ? 1 : 0);

        const options = {
          url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:auth&action=login',
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: formData
        };

        const { data } = await axios(options);

        if (
          Boolean(data) &&
          Boolean(data.errors) &&
          Boolean(data.errors.length)
        ) {
          this.errorsAfterSubmit = data.errors
        } else {
          this.errorsAfterSubmit = []
        }

        if (
          typeof data.errors !== 'undefined'
          && typeof data.errors[0] !== 'undefined'
          && data.errors[0].customData === 'acceptPolicy'
        ) {
          this.$emit('showAcceptPolicyModal');
        }

        if (data.status === 'error') {
          console.log(data.errors);
        } else {
          this.reset();
          try {
            const href = this.tabLk ? `${this.resultLkRoute}?tab=${this.tabLk}` : this.resultLkRoute
            window.location.href = this.hrefWithRegion(href);
          } catch (exception) {
            window.location.reload();
          }
        }
      }
    },
    reset() {
      this.$v.$reset();
      this.form.email = "";
      this.form.password = "";
    },
    changeForm(name) {
      this.$emit("onChangeForm", name);
    },
    submitFormFromEnter(e) {
      if (e.key === "Enter" || e.keyCode === 13) {
        this.submitForm()
      }
    },
    setTabLk(tab) {
      this.tabLk = tab
    }
  },
  mounted() {
    document.addEventListener('keydown', this.submitFormFromEnter)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.submitFormFromEnter)
    this.$root.$off('goToTabAfterAuth', this.setTabLk)
  }
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".auth-form";

#{$b} {
  font-family: $font-family-inter;

  &__bottom {
    display: block;
    text-align: center;
    color: $black-true;

    // .auth-form__bottom-btn
    &-btn {
      width: 208px;
      justify-content: center;
    }

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3;

      &:not(:first-child) {
        margin-top: 32px;
      }

      @include mobile {
        margin-left: -2px;
        margin-right: -2px;
      }

      a {
        color: $color-base-origin;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .button {
      display: flex;
      width: 100%;
    }
  }

  &__field {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 31px;
    }
  }

  &__error {
    position: absolute;
    left: 0;
    top: calc(100% + 3px);
    font-size: 12px;
    line-height: 160%;
    color: $color-error;

    @include mobile-sm {
      font-size: 10px;
      line-height: 1.3;
    }
  }

  &__recovery-link {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    line-height: 1.3;
    color: $color-base-origin;
    text-decoration: none;

    @include mobile {
      font-size: 12px;
    }
  }

  // .auth-form__errors-after-submit
  &__errors-after-submit {
    & > *:last-child {
      margin-bottom: 31px;
    }
  }
}
</style>
