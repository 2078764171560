<template>
  <a
    :href="card.url"
    :target="card.target"
    class="navigation-card"
    :class="`navigation-card--mode-${mode}`"
    @click="$emit('click', card)"
  >
    <component
      :is="isChildCard ? 'custom-button' : 'div'"
      class="navigation-card__btn"
      size="custom"
      theme="child-primary"
    >
      <div class="navigation-card__inner">
        <img :src="card.icon" class="navigation-card__icon navigation-card__icon--base-icon">
        <img :src="card.iconWhite || card.icon" class="navigation-card__icon navigation-card__icon--light-icon">
        <span class="navigation-card__name fw-500" v-html="card.name" />
      </div>
    </component>
  </a>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    isChildCard() {
      return this.card.isChild
    },

    mode() {
      return this.isChildCard ? 'child' : 'default'
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.navigation-card';
$gap: 20px;

#{$b} {
  height: 100%;
  display: block;
  color: $black-true;
  cursor: pointer;
  text-decoration: none;
  transition: $transtion-default;
  padding: calc($gap + 3px) 0;

  @include tablet {
    padding: 0;
  }

  @include hover {
    color: $white-true;

    #{$b}__inner {
      &::before,
      &::after {
        height: calc(100% + ($gap * 2));

        @include tablet {
          height: 100%;
        }
      }

      &::after {
        opacity: 1;
      }

      &::before {
        box-shadow: none;
      }
    }

    #{$b}__icon {
      &--base-icon {
        display: none;
      }

      &--light-icon {
        display: block;
      }
    }
  }

  // .navigation-card__inner
  &__inner {
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 28px auto;
    align-items: center;
    gap: 22px;
    padding: 17px 17px 17px 24px;

    @include mobile {
      gap: 32px;
      padding-left: 34px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      transition: $transtion-default;
      border-radius: 20px;
      box-shadow: 0px 5px 20px rgba($black-true, 0.1);
      transform: translateY(-50%) translateX(-50%);
    }

    &::before {
      max-width: calc(100% - 2px);
      max-height: calc(100% + ($gap * 2) - 2px);
      background-color: $white-true;

      #{$b}--mode-child & {
        opacity: 0 !important;
      }
    }

    &::after {
      opacity: 0;
      background: linear-gradient(0deg, $color-base-origin 0%, #2400A8 100%);

      #{$b}--mode-child & {
        opacity: 0 !important;
      }
    }
  }

  // .navigation-card__icon
  &__icon {
    width: 100%;
    height: auto;
    transition: $transtion-default;

    // .navigation-card__icon--base-icon
    &--base-icon {
      #{$b}--mode-child & {
        display: none;
      }
    }

    // .navigation-card__icon--light-icon
    &--light-icon {
      display: none;

      #{$b}--mode-child & {
        display: block;
      }
    }
  }

  &__icon,
  &__name {
    position: relative;
    z-index: 1;
  }

  // .navigation-card__btn
  #{$b}__btn {
    width: 100%;
    height: 100%;
    padding: 0;
    justify-content: flex-start;

    --before-skewY: 2deg;
    --before-translateX: -2px;
    --before-translateY: -12px;

    --after-skewY: 2deg;
    --after-translateX: 2px;
    --after-translateY: 12px;

    .custom-button__content {
      justify-content: flex-start;
    }

    @include tablet {
      --before-skewY: 1deg;
      --before-translateX: -1px;
      --before-translateY: -7px;

      --after-skewY: 1deg;
      --after-translateX: 1px;
      --after-translateY: 7px;
    }
  }
}
</style>
